<template>
  <div class="address-form">
    <b-collapse
      animation="slide"
      :open="isOpen"
      @open="isOpen = true"
      @close="isOpen = false"
      :aria-id="'contentIdForA11y5-' + index"
      :class="`card ${isOpen ? 'focus' : ''} ${isComplete ? '' : 'is-danger'}`"
    >
      <template #trigger="props">
        <div
          :class="`card-header ${props.open ? 'focus' : ''}`"
          role="button"
          :aria-controls="'contentIdForA11y5-' + index"
          :aria-expanded="props.open"
        >
          <p class="card-header-title is-danger" v-show="!isComplete">Address Form Incomplete</p>
          <p class="card-header-title" v-show="isComplete">
            <span class="is-capitalized">{{ address.addressType }} Address</span>: {{ fullAddress }}
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'chevron-up' : 'chevron-down'">
            </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <AddressFormFields
          :index="index"
          :address="address"
          :editable="editable"
          :defaultAddressType="defaultAddressType"
          :hasGoogleAutoComplete="hasGoogleAutoComplete"
          @addressFormCompleted="onAddressFormChange"
        />
        <div v-if="hasDeleteButton && editable" class="mt-5">
          <b-button type="is-danger" size="is-small" @click="onAddressDeletion">Delete</b-button>
        </div>
      </div>
      <footer class="card-footer">
        <a class="card-footer-item" @click="isOpen = false">Collapse Address Form</a>
      </footer>
    </b-collapse>
  </div>
</template>
<script>
import AddressFormFields from '@components/form/address-form-fields.vue'

export default {
  components: {
    AddressFormFields,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    defaultAddressType: {
      type: String,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    hasGoogleAutoComplete: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isComplete: false,
    }
  },
  computed: {
    streetAddress() {
      return [this.address.streetNumber, this.address.route].filter((string) => string).join(' ')
    },
    fullAddress() {
      return `${this.streetAddress}, ${this.address.locality} ${this.address.administrativeAreaLevel1}, ${this.address.country}`
    }
  },
  methods: {
    onAddressFormChange(completionStatus) {
      this.isComplete = completionStatus
      this.$emit('addressFormCompleted', completionStatus)
    },
    onAddressDeletion() {
      this.$emit('onAddressDeletion')
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.address-form {
  .card {
    font-size: 18px;
    border-radius: 12px;
    box-shadow: none;
    border: none;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &.focus {
      border: 1px solid var(--addy-blue-light-1);
    }
    &.is-danger {
      .card-header {
        border-color: #f14668;
      }
    }
    div.card-header {
      background-color: white;
      border-radius: 12px;
      border: 1px solid #dbdbdb;
      height: 40px;
      box-shadow: none;
      &:hover {
        border-color: #b5b5b5;
        p.card-header-title,
        span {
          color: $addyBlue;
        }
      }
      &.focus {
        border: none;
      }
      .card-header-title {
        font-weight:  500;
        padding: 7px 11px;
        &.is-danger {
          color: #f14668;
        }
      }
      .card-header-icon {
        font-size: 15px;
        padding: 0 10px;
        ::v-deep .icon > i {
          color: var(--addy-blue-light-1);
        }
      }
    }
    .card-footer {
      .card-footer-item {
        color: var(--addy-blue-light-1);
        &:hover {
          color: $addyBlue;
        }
      }
    }
  }
  .button ::v-deep span {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
